import React from "react"
import AuthorImage from "./components/AuthorImage"

export default function DaZeroAlBrand() {
  return (
    <section className="section-cover">
      <div className="container">
        <div className="columns is-centered px-5">
          <div className="column is-four-fifths">
            <div className="columns is-widescreen">
              <div className="column is-one-third">
                <h2>DA ZERO<br />AL BRAND</h2>
                <h3 className="mt-2">Guida completa al marketing strategico,
                  dal posizionamento alla comunicazione.</h3>
                <p className="mt-6"><a
                  rel="noreferrer" target="_blank"
                  href="https://www.amazon.it/Zero-Brand-strategico-Posizionamento-Comunicazione/dp/8868958635/ref=sr_1_1?__mk_it_IT=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=da+zero+al+brand&qid=1604353796&s=digital-text&sr=1-1-catcorr">Acquista</a>
                </p>
              </div>
              <div className="column book-cover">
                <AuthorImage fileName="cover.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
